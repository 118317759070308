$(function () {

    /**
     * PC版：検索フォームエンターキー
     */
    $('#header-search-text').keypress(function (e) {
        const enter_key_code = 13;
        if (e.keyCode == enter_key_code) {
            $('#search-button, #search-button-scrolled').trigger('click');
        }
    });
    /**
     * PC版：検索フォームエンターキー
     */
    $('#header-search-text-scrolled').keypress(function (e) {
        const enter_key_code = 13;
        if (e.keyCode == enter_key_code) {
            $('#search-button, #search-button-scrolled').trigger('click');
        }
    });
    /**
     * PC版：検索フォーム
     */
    $('#search-button').click(function () {
        let keyword = $("input[name=search-text]").val();
        let header_category = $("select[name=header-category]").val();

        if (keyword !== '') {
            keyword = encodeURI(keyword);
            if (header_category !== '' && header_category !== undefined) {
                window.location.href = '/item/list?category_code=' + header_category + '&keyword=' + keyword;
            } else {
                window.location.href = '/item/list?keyword=' + keyword;
            }
        }
    });

    /**
     * PC版：検索フォーム
     */
    $('#search-button-scrolled').click(function () {
        let keyword = $("input[name=search-text-scrolled]").val();

        if (keyword !== '') {
            keyword = encodeURI(keyword);
            window.location.href = '/item/list?keyword=' + keyword;
        }
    });

    /**
     * PC版：カテゴリークリック時
     */
    $('.search-category').click(function () {
        let is_active = $(this).hasClass('menu-bottom-bar-on')
        $(this).parent().find('.menu-bottom-bar-on').removeClass('menu-bottom-bar-on');
        $('.search-category-area').fadeOut();
        $('.search-symptoms-area').fadeOut();
        $('#header-overflow-area').fadeOut();
        if (is_active) {
            $('.search-category-area').fadeOut(100);
            $('#header-overflow-area').fadeOut(10);
            $(this).removeClass('menu-bottom-bar-on');
        } else {
            $('.search-category-area').fadeIn(100);
            $('#header-overflow-area').fadeIn(10);
            $(this).addClass('menu-bottom-bar-on');
        }
    });

    /**
     * PC版：症状から探すクリック時
     */
    $('.search-symptoms').click(function () {
        let is_active = $(this).hasClass('menu-bottom-bar-on')
        $(this).parent().find('.menu-bottom-bar-on').removeClass('menu-bottom-bar-on');
        $('.search-category-area').fadeOut();
        $('.search-symptoms-area').fadeOut();
        $('#header-overflow-area').fadeOut();
        if (is_active) {
            $('.search-symptoms-area').fadeOut(100);
            $('#header-overflow-area').fadeOut(10);
            $(this).removeClass('menu-bottom-bar-on');
        } else {
            $('.search-symptoms-area').fadeIn(100);
            $('#header-overflow-area').fadeIn(10);
            $(this).addClass('menu-bottom-bar-on');
        }
    });

    /**
     *  PC：カテゴリークリック時(スクロール版)
     */
    $('.scroll-search-category').click(function () {
        let is_active = $(this).hasClass('menu-bottom-bar-on')
        $(this).parent().find('.menu-bottom-bar-on').removeClass('menu-bottom-bar-on');
        $('.scroll-search-category-area').fadeOut();
        $('.scroll-search-symptoms-area').fadeOut();
        $('#scroll-header-overflow-area').fadeOut();
        if (is_active) {
            $('.scroll-search-category-area').fadeOut(100);
            $('#scroll-header-overflow-area').fadeOut(10);
            $(this).removeClass('menu-bottom-bar-on');
        } else {
            $('.scroll-search-category-area').fadeIn(100);
            $('#scroll-header-overflow-area').fadeIn(10);
            $(this).addClass('menu-bottom-bar-on');
        }
    });

    /**
     *  PC：症状から探すクリック時(スクロール版)
     */
    $('.scroll-search-symptoms').click(function () {
        let is_active = $(this).hasClass('menu-bottom-bar-on')
        $(this).parent().find('.menu-bottom-bar-on').removeClass('menu-bottom-bar-on');
        $('.scroll-search-category-area').fadeOut();
        $('.scroll-search-symptoms-area').fadeOut();
        $('#scroll-header-overflow-area').fadeOut();
        if (is_active) {
            $('.scroll-search-symptoms-area').fadeOut(100);
            $('#scroll-header-overflow-area').fadeOut(10);
            $(this).removeClass('menu-bottom-bar-on');
        } else {
            $('.scroll-search-symptoms-area').fadeIn(100);
            $('#scroll-header-overflow-area').fadeIn(10);
            $(this).addClass('menu-bottom-bar-on');
        }
    });

    /**
     * ハンバーガーアイコンクリック時
     */
    $('.hamburger-icon').click(function () {
        $('.overflow-area').removeClass('no-display');

        //検索ダイアログを閉じる
        if ($('.search-dialog:not("no-display")')) {
            $('.search-icon-delete').addClass('no-display');
            $('.search-dialog').addClass('no-display');
        }
    });

    /**
     * ハンバーガーアイコンクリック時
     */
    $('.menu-btn-close').click(function () {
        $('.overflow-area').addClass('no-display');
    });

    /**
     * スマホ：検索アイコンクリック時
     */
    $('.search-icon').click(function () {
        $(this).next('.search-icon-delete').removeClass('no-display');
        $('.overflow-area').removeClass('no-display');
        $('.search-dialog').removeClass('no-display');
    });

    /**
     * スマホ版：検索フォーム
     */
    $('#search-button-sp').click(function () {
        let keyword = $("input[name=search-text-sp]").val();

        if (keyword !== '') {
            keyword = encodeURI(keyword);
            window.location.href = '/item/list?keyword=' + keyword;
        }
    });

    /**
     * スマホ：検索メニューDeleteクリック時
     */
    $('.search-icon-delete').click(function () {
        $(this).addClass('no-display');
        $('.overflow-area').addClass('no-display');
        $('.search-dialog').addClass('no-display');
    });

    /**
     * マウスオーバー時の処理
     */
    $('main').hover(
        function () {
            $('.search-category-area').fadeOut(100);
            $('.scroll-search-category-area').fadeOut(100);
            $('.search-symptoms-area').fadeOut(100);
            $('.scroll-search-symptoms-area').fadeOut(100);
            $('#header-overflow-area').fadeOut(100);
            $('#scroll-header-overflow-area').fadeOut(100);
            $('.menu-bottom').removeClass('menu-bottom-bar-on');
        }
    );

    /**
     * 吹き出しの削除アイコンクリック
     */
    $('.balloon-close-icon').click(function () {
        $('.message-balloon').remove();
    });


    function encodeURI(keyword) {
        let encode_keyword = '';
        for (let i = 0; i < keyword.length; i++) {
            if (keyword[i].match(/^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/) ? false : true) {
                encode_keyword += encodeURIComponent(keyword[i]);
            } else {
                encode_keyword += keyword[i];
            }
        }
        return encode_keyword;
    }
});
